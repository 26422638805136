import firebase from 'firebase'

class Auth {
    constructor() {
        this.authenticated = false;
        this.idToken = "";
    }



    login(cb) {
        firebase.auth().currentUser.getIdToken().then(idToken => {
            this.idToken = idToken;
            this.authenticated = true;
            cb();
        })
    }

    getToken() {
        return this.idToken;
    }

    isAuthenticated() {
        return this.authenticated;
    }
}

export default new Auth();