import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Auth from '../services/auth';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (Auth.isAuthenticated()) {
                    return <Component {...props} token={Auth.getToken() } state={props.location.state} />
                } else {
                    return <Redirect to={{
                        pathname: "/",
                        state: {
                            from: props.location
                        }
                    }} />
                }
            }} />
    )
}